import {
  ADDRESS_BOOK_CREATE_NEW_PATH,
  ADDRESS_BOOK_CREATE_PATH,
  ADDRESS_BOOK_ITEM_NEW_PATH,
  ADDRESS_BOOK_ITEM_PATH,
  ADDRESS_BOOK_NEW_PATH,
  ADDRESS_BOOK_PATH,
  BANK_LIST_PATH,
  COMMUNICATION_SETTINGS_CREATE_PATH,
  COMMUNICATION_SETTINGS_ITEM_PATH,
  COMMUNICATION_SETTINGS_LIST_PATH,
  FACEBOOK_SIGN_UP_PATH,
  FINANCE_BALANCE_PATH,
  FINANCE_ORDER_LIST_PATH,
  FINANCE_TRANSACTIONS_PATH,
  FINANCE_TRANSACTIONS_URL,
  FINANCE_WITHDRAW_ITEM_PATH,
  FINANCE_WITHDRAW_LIST_PATH,
  ORDER_CREATE_DEFAULT_PATH,
  ORDER_CREATE_DEFAULT_URL,
  ORDER_CREATE_NEW_PATH,
  ORDER_CREATE_PATH,
  ORDER_CREATE_SUCCESS_PATH,
  ORDER_ITEM_PATH,
  ORDER_ITEM_URL,
  ORDER_ITEM_V2_PATH,
  ORDER_LIST_PATH,
  ORDER_LIST_URL,
  ORDER_LOCATION_PATH,
  ORDER_PUBLIC_ADDRESS_VERIFICATION_PATH,
  ORDER_PUBLIC_CUSTOMER_FEEDBACK_PATH,
  ORDER_RESCHEDULE_TIMESLOT_PATH,
  ORDER_RESCHEDULE_TIMESLOT_V2_PATH,
  ORDER_SORTING_PATH,
  ORDER_UPLOAD_PATH,
  ORDER_UPLOAD_V2_PATH,
  ORDERS_PER_POSTCODES_PATH,
  ORDERS_PER_POSTCODES_PATH_2,
  PUBLIC_ORDER_CONFIRM_PATH,
  PUBLIC_ORDER_TRACK_MAP_PATH,
  PUBLIC_ORDER_TRACK_PATH,
  PUBLIC_ORDER_TRACK_URL,
  RECEIVING_ORDERS_PATH,
  SIGN_UP_PATH,
  VERIFY_PHONE_NUMBER_PATH,
} from "../constants/CustomerPathConstants";
import {
  CHANGE_PASSWORD_PATH,
  DASHBOARD_PATH,
  PROFILE_PATH,
  RECOVER_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
} from "../constants/DashboardPathConstants";
import SignOut from "../components/auth/SignOut";
import ChangePasswordContainer from "../containers/shared/ChangePasswordContainer";
import Root from "../components/app-core/Root";
import NotFound from "../components/app-core/NotFound";
import CustomerRoot from "../containers/customer/CustomerRoot";
import CustomerSignIn from "../containers/customer/CustomerSignIn";
import CustomerSignUp from "../containers/customer/CustomerSignUp";
import CustomerVerify from "../containers/customer/CustomerVerify";
import CustomerProfile from "../containers/customer/CustomerProfile";
import CustomerOrderItem from "../containers/customer/CustomerOrderItem";
import CustomerOrderList from "../containers/customer/CustomerOrderListNew";
import CustomerOrderItemV2 from "../containers/customer/CustomerOrderItemV2";
import CustomerFacebookSignUp from "../containers/customer/CustomerFacebookSignUp";
import CustomerAddressBookItem from "../containers/customer/CustomerAddressBookItem";
import CustomerAddressBookList from "../containers/customer/CustomerAddressBookList";
import CustomerReceivingOrders from "../containers/customer/CustomerReceivingOrders";
import CustomerBalanceContainer from "../containers/customer/CustomerBalanceContainer";
import CustomerAddressBookCreate from "../containers/customer/CustomerAddressBookCreate";
import CustomerBankListContainer from "../containers/customer/CustomerBankListContainer";
import CustomerAddressBookItemNew from "../containers/customer/CustomerAddressBookItemNew";
import CustomerAddressBookListNew from "../containers/customer/CustomerAddressBookListNew";
import CustomerOrderCreateSuccess from "../containers/customer/CustomerOrderCreateSuccess";
import CustomerAddressBookCreateNew from "../containers/customer/CustomerAddressBookCreateNew";
import CustomerOrderCreateContainer from "../containers/customer/CustomerOrderCreateContainer";
import CustomerOrderSortingContainer from "../containers/customer/CustomerOrderSortingContainer";
import CustomerTransactionsContainer from "../containers/customer/CustomerTransactionsContainer";
import CustomerUploadOrdersContainer from "../containers/customer/CustomerUploadOrdersContainer";
import CustomerOrderLocationContainer from "../containers/customer/CustomerOrderLocationContainer";
import CustomerOrderTrackingContainer from "../containers/customer/CustomerOrderTrackingContainer";
import CustomerOrderCreateNewContainer from "../containers/customer/CustomerOrderCreateNewContainer";
import CustomerPublicFeedbackContainer from "../containers/customer/CustomerPublicFeedbackContainer";
import CustomerUploadOrdersV2Container from "../containers/customer/CustomerUploadOrdersV2Container";
import CustomerCODWithdrawItemContainer from "../containers/customer/CustomerCODWithdrawItemContainer";
import CustomerCODWithdrawListContainer from "../containers/customer/CustomerCODWithdrawListContainer";
import CustomerCommunicationSettingsItem from "../containers/customer/CustomerCommunicationSettingsItem";
import CustomerCommunicationSettingsList from "../containers/customer/CustomerCommunicationSettingsList";
import CustomerFinanceOrderListContainer from "../containers/customer/CustomerFinanceOrderListContainer";
import CustomerOrderTrackingMapContainer from "../containers/customer/CustomerOrderTrackingMapContainer";
import CustomerCommunicationSettingsCreate from "../containers/customer/CustomerCommunicationSettingsCreate";
import CustomerOrderCreateDefaultContainer from "../containers/customer/CustomerOrderCreateDefaultContainer";
import CustomerRescheduleTimeSlotContainer from "../containers/customer/CustomerRescheduleTimeSlotContainer";
import CustomerPublicAddressVerifyContainer from "../containers/customer/CustomerPublicAddressVerifyContainer";
import CustomerRescheduleTimeSlotContainerV2 from "../containers/customer/CustomerRescheduleTimeSlotContainerV2";
import CustomerOrderRescheduleConfirmContainer from "../containers/customer/CustomerOrderRescheduleConfirmContainer";
import { updateQuery } from "../../shared/helpers/UrlUtils";
import CustomerPostCreate from "../containers/customer/CustomerPostCreate";
import CustomerPostcodesCoordinatesContainer from "../containers/customer/CustomerPostcodesCoordinatesContainer";
import CustomerRecoverPasswordContainer from "../containers/shared/CustomerRecoverPasswordContainer";

export default {
  path: "/",
  component: Root,
  indexRoute: {
    onEnter: (state, replace) =>
      replace(updateQuery(ORDER_LIST_URL, state.location.query)),
  },
  childRoutes: [
    // Redirects.

    {
      path: "l(/:ids)",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(PUBLIC_ORDER_TRACK_URL, { ids: state.params.ids }),
        ),
    },

    {
      path: "n(/:ids)",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(PUBLIC_ORDER_TRACK_URL, { ids: state.params.ids }),
        ),
    },

    {
      path: "track/:ids",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(PUBLIC_ORDER_TRACK_URL, { ids: state.params.ids }),
        ),
    },

    {
      path: "order-tracking(/:ids)",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(PUBLIC_ORDER_TRACK_URL, { ids: state.params.ids }),
        ),
    },

    {
      path: "order/:id",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(ORDER_ITEM_URL + state.params.id, state.location.query),
        ),
    },

    {
      path: "transaction/:id",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(FINANCE_TRANSACTIONS_URL, { id: state.params.id }),
        ),
    },

    {
      path: "dashboard/order/list/all",
      onEnter: (state, redirect) =>
        redirect(updateQuery(ORDER_LIST_URL, state.location.query)),
    },

    // Auth.

    {
      path: SIGN_OUT_PATH,
      component: SignOut,
    },
    {
      path: SIGN_IN_PATH,
      component: CustomerSignIn,
    },
    {
      path: SIGN_UP_PATH,
      component: CustomerSignUp,
    },
    {
      path: FACEBOOK_SIGN_UP_PATH,
      component: CustomerFacebookSignUp,
    },
    {
      path: RECOVER_PASSWORD_PATH,
      component: CustomerRecoverPasswordContainer,
    },
    {
      path: CHANGE_PASSWORD_PATH,
      component: ChangePasswordContainer,
    },

    // Public pages.

    {
      path: ORDER_LOCATION_PATH,
      component: CustomerOrderLocationContainer,
    },
    {
      path: PUBLIC_ORDER_TRACK_PATH,
      component: CustomerOrderTrackingContainer,
    },
    {
      path: PUBLIC_ORDER_TRACK_MAP_PATH,
      component: CustomerOrderTrackingMapContainer,
    },
    {
      path: PUBLIC_ORDER_CONFIRM_PATH,
      component: CustomerOrderRescheduleConfirmContainer,
    },
    {
      path: VERIFY_PHONE_NUMBER_PATH,
      component: CustomerVerify,
    },
    {
      path: ORDER_RESCHEDULE_TIMESLOT_PATH,
      components: CustomerRescheduleTimeSlotContainer,
    },
    {
      path: ORDER_RESCHEDULE_TIMESLOT_V2_PATH,
      components: CustomerRescheduleTimeSlotContainerV2,
    },
    {
      path: ORDER_PUBLIC_ADDRESS_VERIFICATION_PATH,
      components: CustomerPublicAddressVerifyContainer,
    },
    {
      path: ORDER_PUBLIC_CUSTOMER_FEEDBACK_PATH,
      components: CustomerPublicFeedbackContainer,
    },

    // Dashboard.

    {
      path: DASHBOARD_PATH,
      component: CustomerRoot,
      indexRoute: {
        onEnter: (state, replace) =>
          replace(updateQuery(ORDER_LIST_URL, state.location.query)),
      },
      childRoutes: [
        {
          path: PROFILE_PATH,
          component: CustomerProfile,
        },
        // {
        //   path: CHARTS_PATH,
        //   component: CustomerDashboardContainer,
        // },
        {
          path: ORDER_LIST_PATH,
          component: CustomerOrderList,
        },
        {
          path: ORDERS_PER_POSTCODES_PATH,
          component: CustomerPostcodesCoordinatesContainer,
        },
        {
          path: ORDERS_PER_POSTCODES_PATH_2,
          component: CustomerPostcodesCoordinatesContainer,
        },
        {
          path: ORDER_ITEM_PATH,
          component: CustomerOrderItem,
        },
        {
          path: ORDER_ITEM_V2_PATH,
          component: CustomerOrderItemV2,
        },
        // Post order
        {
          path: ORDER_CREATE_DEFAULT_PATH,
          component: CustomerPostCreate,
        },
        {
          path: ORDER_CREATE_PATH,
          component: CustomerOrderCreateContainer,
        },
        {
          path: ORDER_CREATE_NEW_PATH,
          component: CustomerOrderCreateNewContainer,
        },
        {
          path: ORDER_CREATE_DEFAULT_URL,
          component: CustomerOrderCreateDefaultContainer,
        },
        {
          path: ORDER_CREATE_SUCCESS_PATH,
          component: CustomerOrderCreateSuccess,
        },
        {
          path: ORDER_UPLOAD_PATH,
          component: CustomerUploadOrdersContainer,
        },
        {
          path: ORDER_UPLOAD_V2_PATH,
          component: CustomerUploadOrdersV2Container,
        },
        {
          path: ORDER_SORTING_PATH,
          component: CustomerOrderSortingContainer,
        },
        {
          path: FINANCE_BALANCE_PATH,
          component: CustomerBalanceContainer,
        },
        {
          path: FINANCE_TRANSACTIONS_PATH,
          component: CustomerTransactionsContainer,
        },
        {
          path: FINANCE_ORDER_LIST_PATH,
          component: CustomerFinanceOrderListContainer,
        },
        {
          path: FINANCE_WITHDRAW_LIST_PATH,
          component: CustomerCODWithdrawListContainer,
        },
        {
          path: FINANCE_WITHDRAW_ITEM_PATH,
          component: CustomerCODWithdrawItemContainer,
        },
        {
          path: BANK_LIST_PATH,
          component: CustomerBankListContainer,
        },
        {
          path: ADDRESS_BOOK_PATH,
          component: CustomerAddressBookList,
        },
        {
          path: ADDRESS_BOOK_NEW_PATH,
          component: CustomerAddressBookListNew,
        },
        {
          path: ADDRESS_BOOK_CREATE_PATH,
          component: CustomerAddressBookCreate,
        },
        {
          path: ADDRESS_BOOK_CREATE_NEW_PATH,
          component: CustomerAddressBookCreateNew,
        },
        {
          path: COMMUNICATION_SETTINGS_LIST_PATH,
          component: CustomerCommunicationSettingsList,
        },
        {
          path: COMMUNICATION_SETTINGS_CREATE_PATH,
          component: CustomerCommunicationSettingsCreate,
        },
        {
          path: COMMUNICATION_SETTINGS_ITEM_PATH,
          component: CustomerCommunicationSettingsItem,
        },
        {
          path: ADDRESS_BOOK_ITEM_PATH,
          component: CustomerAddressBookItem,
        },
        {
          path: ADDRESS_BOOK_ITEM_NEW_PATH,
          component: CustomerAddressBookItemNew,
        },

        {
          path: RECEIVING_ORDERS_PATH,
          component: CustomerReceivingOrders,
        },
      ],
    },

    // Not Found Handler.

    { path: "*", component: NotFound },
  ],
};
