import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signIn, facebookSignIn } from "../../actions/CustomerAuthActions";
import ResponseError from "../../helpers/ResponseError";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { SIGN_UP_URL } from "../../constants/CustomerPathConstants";
import NavigateFromSignIn from "../../components/auth/NavigateFromSignIn";
import {
  isTokenValid,
  isTokenPersisting,
  isFacebookSigningIn,
} from "../../../shared/reducers/AuthReducer";
import CustomerSignInForm from "../../components/auth/CustomerSignInForm";
import { formatPhone2 } from "../../helpers/FormatUtils";

const enhancer = compose(
  connect(
    state => ({
      isTokenValid: isTokenValid(state),
      isLoading: isTokenPersisting(state) || isFacebookSigningIn(state),
    }),
    { signIn, facebookSignIn, showErrorMessage },
  ),
);

CustomerSignIn.propTypes = {
  signIn: PropTypes.func,
  isLoading: PropTypes.bool,
  isTokenValid: PropTypes.bool,
};

function CustomerSignIn(props) {
  if (props.isTokenValid) {
    return <NavigateFromSignIn />;
  }

  return (
    <CustomerSignInForm
      signUpUrl={SIGN_UP_URL}
      isLoading={props.isLoading}
      // onSignInWithFacebookClick={() =>
      //   props.facebookSignIn().catch(props.showErrorMessage)
      // }
      onSubmit={values =>
        props
          .signIn({
            ...values,
            isCustomer: true,
            username: formatPhone2(values.username),
          })
          .catch(ResponseError.throw)
      }
    />
  );
}

export default enhancer(CustomerSignIn);
