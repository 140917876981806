import React from "react";
import _ from "lodash";
import Immutable, { fromJS, List, Map, OrderedMap } from "immutable";
import { compose, mapPropsStream, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  AccountBalance as ActionAccountBalance,
  AssignmentReturned as ActionAssignmentReturned,
  Dashboard as ActionDashboard,
  SwapHoriz as ActionSwapHoriz,
} from "@material-ui/icons";
import TruckDelivery from "../icons/TruckDelivery";
import CommunicationIcon from "../icons/CommunicationIcon";
import AppLayout from "../app-layout/AppLayout";
import AppSidebar from "../app-sidebar/AppSidebar";
import AppSidebarLink from "../app-sidebar/AppSidebarLink";
import AppSidebarBlock from "../app-sidebar/AppSidebarBlock";
import AppSidebarProfileBlock from "../app-sidebar/AppSidebarProfileBlock";
import { isEqualData } from "../../helpers/DataUtils";
import { layoutIcon } from "../../helpers/MarketplaceHelper";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  BANK_LIST_URL,
  CHARTS_URL,
  COMMUNICATION_SETTINGS_LIST_URL,
  FINANCE_ORDER_LIST_URL,
  FINANCE_TRANSACTIONS_URL,
  FINANCE_WITHDRAW_LIST_URL,
  ORDER_LIST_URL,
} from "../../constants/CustomerPathConstants";
import customerMenuList from "../../constants/CustomerMarketplaceMenuConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplace: getMarketplace(state),
  })),
  withRouter,
  withState("state", "setState", {
    isOpen: true,
    menuName: "",
    loaded: true,
  }),
  mapPropsStream((propsStream) => {
    const settingsStream = propsStream
      .take(1)
      .filter((props) => props.marketplace)
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const menuList = props.marketplace.getIn(
          ["setting", "settings", "leftMenuCustomer"],
          Map(),
        );
        const menu = List().asMutable();
        let currentMenu = null;

        customerMenuList.forEach((item) => {
          const enabledItem = menuList.get(item.slug, null);
          if (enabledItem && enabledItem.get("active", false)) {
            const menuItem = Map({
              ...item,
              name: enabledItem.get("name", null),
            });

            if (props.slug && props.slug === item.slug) {
              currentMenu = menuItem;
            }

            menu.push(menuItem);
          }
        });

        return Map({
          currentMenu,
          settings: fromJS(_.groupBy(menu.toJS(), "group")).sortBy((item) =>
            item.get(0).get("ind"),
          ),
        });
      })
      .startWith(OrderedMap())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(settingsStream, (props, settings) => ({
        ...props,
        settings: settings.get("settings", null),
        currentMenu: settings.get("currentMenu", null),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

export const sidebar = (data) => {
  const { settings, getLocalisationMessage } = data;
  if (settings && settings.size > 0) {
    return (
      <AppSidebar>
        {settings
          .map((group, key) => {
            const groupName = _.startCase(key);
            return (
              <AppSidebarBlock title={getLocalisationMessage(key, groupName)}>
                {group
                  .map((item) => (
                    <AppSidebarLink
                      leftIcon={layoutIcon(item.get("icon"))}
                      to={
                        item.has("params")
                          ? updateQuery(
                              item.get("url"),
                              item.get("params").toJS(),
                            )
                          : item.get("url")
                      }
                    >
                      {item.get("name") ||
                        getLocalisationMessage(
                          item.get("slug"),
                          _.startCase(item.slug),
                        )}
                    </AppSidebarLink>
                  ))
                  .toArray()}
              </AppSidebarBlock>
            );
          })
          .toArray()}
        <AppSidebarProfileBlock />
      </AppSidebar>
    );
  }

  return (
    <AppSidebar>
      <AppSidebarBlock title={getLocalisationMessage("dashboard", "Dashboard")}>
        <AppSidebarLink leftIcon={<ActionDashboard />} to={CHARTS_URL}>
          {getLocalisationMessage("dashboard", "Dashboard")}
        </AppSidebarLink>
      </AppSidebarBlock>

      <AppSidebarBlock title={getLocalisationMessage("orders", "Orders")}>
        <AppSidebarLink leftIcon={<TruckDelivery />} to={ORDER_LIST_URL}>
          {getLocalisationMessage("domestic_orders", "Domestic Orders")}
        </AppSidebarLink>
      </AppSidebarBlock>

      <AppSidebarBlock title={getLocalisationMessage("finance", "Finance")}>
        <AppSidebarLink
          to={FINANCE_ORDER_LIST_URL}
          leftIcon={<ActionAccountBalance />}
        >
          {getLocalisationMessage("orders", "Orders")}
        </AppSidebarLink>
        <AppSidebarLink
          to={FINANCE_WITHDRAW_LIST_URL}
          leftIcon={<ActionAssignmentReturned />}
        >
          {getLocalisationMessage("withdraw_requests", "Withdraw Requests")}
        </AppSidebarLink>

        <AppSidebarLink
          to={FINANCE_TRANSACTIONS_URL}
          leftIcon={<ActionSwapHoriz />}
        >
          {getLocalisationMessage("transactions", "Transactions")}
        </AppSidebarLink>

        <AppSidebarLink to={BANK_LIST_URL} leftIcon={<ActionAccountBalance />}>
          {getLocalisationMessage("banks", "Banks")}
        </AppSidebarLink>
      </AppSidebarBlock>

      <AppSidebarBlock title={getLocalisationMessage("settings", "Settings")}>
        <AppSidebarLink
          to={COMMUNICATION_SETTINGS_LIST_URL}
          leftIcon={<CommunicationIcon />}
        >
          {getLocalisationMessage("communication", "Communication")}
        </AppSidebarLink>
      </AppSidebarBlock>
      <AppSidebarProfileBlock />
    </AppSidebar>
  );
};

CustomerAppLayout.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  appBarRightAction: PropTypes.node,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  settings: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  currentMenu: PropTypes.instanceOf(Map),
  state: PropTypes.object,
  setState: PropTypes.func,
  router: PropTypes.object,
};

function CustomerAppLayout(props) {
  const { getLocalisationMessage, title } = props;
  let pageTitle = title;

  if (props.currentMenu)
    pageTitle =
      props.currentMenu.get("name") ||
      getLocalisationMessage(
        props.currentMenu.get("slug"),
        _.startCase(props.currentMenu.get("slug")),
      ) ||
      title;

  if (!props.marketplace)
    return (
      <AppLayout
        {...props}
        title={title}
        sidebar={
          <AppSidebar>
            <AppSidebarBlock
              title={getLocalisationMessage("loading", "Loading...")}
            >
              {getLocalisationMessage("loading", "Loading...")}
            </AppSidebarBlock>
            <AppSidebarProfileBlock />
          </AppSidebar>
        }
      />
    );

  return <AppLayout {...props} title={pageTitle} sidebar={sidebar(props)} />;
}

export default enhancer(CustomerAppLayout);
