import React, { useEffect, useRef, useState } from "react";
import { List, Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  MenuItem,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Add, FilterList, Print, Refresh, Search } from "@material-ui/icons";
import { isEmpty, toJS } from "../../helpers/DataUtils";
import { formatDateValuesForDownloadCSV } from "../../helpers/OrderListHelper";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import { COLUMN } from "../../components/orders-core/MUITable";
import OrdersMUITable from "../../components/orders-core/OrdersMUITable";
import _ from "lodash";
import customerColumnOptions from "../../constants/marketplace/CustomerCustomizableOrderListContainer";
import SimpleTabs from "../../components/ui-core/SimpleTabs";
import { ALL } from "../../constants/OverallStatusTabs";
import {
  COMPLETED,
  IN_TRANSIT,
  PREPARED_FOR_TRANSIT,
} from "../../constants/OrderStatusCodes";
import fp from "lodash/fp";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import ChipTextField from "../../components/deprecated/ChipTextField";
import {
  getOrderListWithOutStream,
  getDynamicFields,
} from "../../api/customer/CustomerOrderApi";
import CustomerOrderFilterWrapper from "../../wrappers/customer/CustomerOrderFilterWrapper";
import CustomerOrderDetailsDialogWrapperV2 from "../../wrappers/customer/CustomerOrderDetailsDialogWrapperV2";
import { ORDER_CREATE_DEFAULT_URL } from "../../constants/CustomerPathConstants";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import {
  IS_DOWNLOADING,
  IS_PRINTING,
} from "../../components/orders-core/AdminPostForm";
import { getPDF } from "../../helpers/FormUtils";
import { getMarketplaceSettings } from "../../reducers/MarketplaceReducer";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

export const ZEBRA_URL = `${API_V2_ROOT_URL}/customer/cn22_shipping_label`;
export const BARCODES_PDF = `${API_V2_ROOT_URL}/customer/barcode_printable`;

const useStyles = makeStyles({
  pageFab: { bottom: "64px" },
  appBarRightAction: { height: "100%" },
  appBarRightActionToggle: {
    whiteSpace: "nowrap",
    marginLeft: "12px",
  },
  mr10: {
    marginRight: 10,
  },
  pLR1rem: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  filterToggle: { marginTop: "12px" },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  titleWithBorder2: {
    textAlign: "center",
    borderBottom: "1px solid #eeeeee",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  createSimpleButton: {
    "& .MuiButton-contained:hover": {
      backgroundColor: "#1976d2",
      color: "#fff",
    },
  },
});
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      i18n: getMessages(state),
      marketplaceSettings: getMarketplaceSettings(state),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
);

const OTHERS = "others";

const CustomerOrderList = ({
  i18n,
  setLocation,
  showErrorMessage: showErrorMessage1,
  userWarehouse,
  hasBatchUpdatePermission,
  createSimpleOrder,
  location,
  marketplaceSettings,
  ...props
}) => {
  const classes = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);
  const [isOpenDynamicDownloadCSV, setIsOpenDynamicDownloadCSV] = useState(
    false,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isOpenBrief, setIsOpenBrief] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderList, setOrderList] = useState([]);

  const [zebra, setZebra] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: ZEBRA_URL,
  });
  const [barcodesPDF, setBarcodesPDF] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: BARCODES_PDF,
  });

  const selectedOrderIds = selectedItems ? selectedItems.map(v => v.id) : [];

  const [tabs, setTabs] = useState([
    {
      title: i18n.get("all"),
      value: ALL,
      count: 0,
    },
    {
      title: i18n.get("not_payed"),
      value: "not_paid",
      count: 0,
    },
    {
      title: i18n.get("taken"),
      value: "in_sorting",
      count: 0,
    },
    {
      title: i18n.get("prepared_for_transit"),
      value: PREPARED_FOR_TRANSIT,
      count: 0,
    },
    {
      title: i18n.get("in_transit"),
      value: IN_TRANSIT,
      count: 0,
    },
    {
      title: i18n.get(COMPLETED),
      value: COMPLETED,
      count: 0,
    },
    {
      title: i18n.get(OTHERS),
      value: "others",
      count: 0,
    },
  ]);
  const [error, setError] = useState();
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line camelcase
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
    state: "all",
    use_solr: true,
    refresh: false,
    with_totals: true,
    search_type: "order_number",
  });
  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  useEffect(() => {
    setIsLoading(true);

    getOrderListWithOutStream(filter)
      .then(res => {
        const tempTotal = _.get(res, "data.total");
        const tempTotals = _.get(res, "data.totals");
        setIsLoading(false);
        setOrderList(_.get(res, "data.list"));
        setError(_.get(res, "data.error"));
        setTotal(tempTotal);
        if (!isEmpty(tempTotals)) {
          setTabs(prev => {
            const tempPrev = prev.map(item => ({
              ...item,
              count: tempTotals[item.value],
            }));

            return tempPrev;
          });
        }
      })
      .catch(err => {
        setIsLoading(false);
        showErrorMessage1(err);
      });
  }, [filter]);

  const handleChangePage = (event, page) =>
    setFilter({
      ...filter,
      page,
      with_totals: false,
    });
  const handleChangeRowsPerPage = event =>
    setFilter({
      ...filter,
      size: parseInt(event.target.value, 10),
      page: 0,
      with_totals: false,
    });

  const orderListColumns = toJS(
    marketplaceSettings.get("orderListColumnsForCustomer", Map()),
  );

  const transformColumns = columnList =>
    Object.keys(columnList).filter(v => columnList[v].active);
  const columns = toJS(customerColumnOptions).filter(v =>
    transformColumns(orderListColumns).some(i => i === v.slug),
  );
  const allSideMenuItems = (
    <MenuButtonMore>
      <MenuItem onClick={() => setIsOpenDynamicDownloadCSV(true)}>
        {i18n.get("dynamic_download_csv", "Download Dynamic CSV")}
      </MenuItem>
    </MenuButtonMore>
  );

  return (
    <CustomerAppLayout title={i18n.get("orders", "Orders")}>
      <OrderListErrorNotification
        error={error}
        onFixSizeError={() => setFilter({ ...filter, size: 0 })}
      />
      <DownloadDynamicCSVDialog
        open={isOpenDynamicDownloadCSV}
        getDynamicFields={getDynamicFields}
        createDownloadCSVHref={v =>
          formatDateValuesForDownloadCSV(filter, v).toJS()
        }
        onRequestClose={() => setIsOpenDynamicDownloadCSV(false)}
      />

      <CustomerOrderFilterWrapper
        showLogistic={true}
        setSelectedItems={setSelectedItems}
        filter={filter}
        setFilter={setFilter}
        withCustomFilters={true}
        open={isOpenFilter}
        onRequestClose={() => setIsOpenFilter(false)}
        closeDialog={() => setIsOpenFilter(false)}
      />

      <CustomerOrderDetailsDialogWrapperV2
        location={location}
        tab={location.query.view_tab}
        isOpen={isOpenBrief}
        setIsOpenBrief={setIsOpenBrief}
        orderId={orderId}
        refreshOrderList={refreshOrderList}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() => {
          setIsOpenBrief(false);
          setOrderId(null);
          props.setLocationQuery(fp.unset("view_tab"));
        }}
      />
      <div className={classes.root}>
        <div className={classes.tabs}>
          <SimpleTabs
            variant="fullWidth"
            items={tabs}
            value={filter.state}
            onChange={(e, state) => {
              setFilter({
                ...filter,
                state,
                with_totals: false,
              });
            }}
          />
        </div>
      </div>
      <OrdersMUITable
        filter={filter}
        setFilter={setFilter}
        orderBy={filter.order_by}
        orderByDirection={filter.order_by_direction}
        isLoading={isLoading}
        isCheckboxEnabled={true}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        size="medium"
        list={orderList}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setOrderId={setOrderId}
        setIsOpenBrief={setIsOpenBrief}
        columns={columns.map(v => ({ ...v, type: v.type || COLUMN }))}
        setOrderBy={v =>
          setFilter({
            ...filter,
            order_by: v,
            with_totals: false,
            order_by_direction: filter.order_by_direction
              ? filter.order_by_direction === "desc"
                ? "asc"
                : "desc"
              : "asc",
          })
        }
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_SPACE_AROUND}
            style={{ padding: ".25rem 1rem" }}
          >
            {selectedOrderIds.length ? (
              <FlexBox justify="flex-end" flex={true}>
                <FlexBox>
                  <CustomButton
                    color={SECONDARY}
                    className={classes.pLR1rem}
                    onClick={() =>
                      getPDF(
                        zebra,
                        setZebra,
                        IS_PRINTING,
                        selectedOrderIds,
                        showErrorMessage1,
                      )
                    }
                    endIcon={
                      zebra[IS_PRINTING] ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <Print />
                      )
                    }
                  >
                    {i18n.get("address_label")}
                  </CustomButton>
                </FlexBox>
                <FlexBox>
                  <CustomButton
                    color={SECONDARY}
                    className={classes.pLR1rem}
                    onClick={() =>
                      getPDF(
                        barcodesPDF,
                        setBarcodesPDF,
                        IS_PRINTING,
                        selectedOrderIds,
                        showErrorMessage1,
                      )
                    }
                    endIcon={
                      barcodesPDF[IS_PRINTING] ? (
                        <CircularProgress size={20} color="white" />
                      ) : (
                        <Print />
                      )
                    }
                  >
                    {i18n.get("orders_barcodes")}
                  </CustomButton>
                </FlexBox>
              </FlexBox>
            ) : (
              <React.Fragment>
                <FlexBox
                  align={ALIGN_CENTER}
                  style={{
                    minWidth: 330,
                    flex: "0 0 auto",
                    paddingRight: ".5rem",
                  }}
                >
                  {/* eslint-disable */}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        search_type: "order_number",
                      })
                    }
                  >
                    {i18n.get("search_by_order_number")}
                  </span>
                  <Switch
                    checked={filter.search_type === "order_number"}
                    onChange={(e, v) =>
                      v
                        ? setFilter({
                            ...filter,
                            search_type: "order_number",
                          })
                        : setFilter({
                            ...filter,
                            search_type: "all",
                          })
                    }
                    color={SECONDARY}
                    name="checkedB"
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setFilter({
                        ...filter,
                        search_type: "all",
                      })
                    }
                  >
                    {i18n.get("search_everything")}
                  </span>
                  {/* eslint-enable */}
                </FlexBox>
                <div className={classes.search} ref={ref}>
                  <Search
                    fontSize="large"
                    style={{
                      color: "rgba(0, 0, 0, 0.23)",
                      top: ".5rem",
                      left: ".5rem",
                      position: "absolute",
                    }}
                  />
                  <ChipTextField
                    style={{
                      padding: ".5rem 1rem 0 3rem",
                      maxHeight: divHeight > 86 && "5.8rem",
                      overflow: divHeight > 86 && "auto",
                    }}
                    fullWidth={true}
                    addOnBlur={false}
                    clearOnBlur={false}
                    value={parseString(filter.search)}
                    disableUnderline={true}
                    placeholder={i18n.get("type_here_to_search")}
                    onChange={v =>
                      setFilter({ ...filter, search: stringifyArray(v) })
                    }
                  />
                </div>
              </React.Fragment>
            )}
            <FlexBox style={{ minWidth: 350 }} align={ALIGN_CENTER}>
              <CustomButton
                onClick={() => setLocation(ORDER_CREATE_DEFAULT_URL)}
                startIcon={<Add />}
                variant={CONTAINED}
                color={SECONDARY}
                style={{ borderRadius: "20px" }}
              >
                {i18n.get("create_new_order", "Create New Order")}
              </CustomButton>

              <IconButton
                className={isLoading && classes.refresh}
                tooltip={i18n.get("refresh", "Refresh")}
                onClick={() => refreshOrderList()}
              >
                <Refresh />
              </IconButton>
              <Tooltip title={i18n.get("filter_orders", "Filter Orders")}>
                <IconButton onClick={() => setIsOpenFilter(true)}>
                  <FilterList />
                </IconButton>
              </Tooltip>
              {allSideMenuItems}
            </FlexBox>
          </FlexBox>
        }
      />
    </CustomerAppLayout>
  );
};
CustomerOrderList.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  marketplaceSettings: PropTypes.instanceOf(Map),
  isOtpSettingsServiceType: PropTypes.instanceOf(List),
  isOtpSettingsTransportationType: PropTypes.instanceOf(List),
  userWarehouse: PropTypes.object,
  location: PropTypes.object,
  hasBatchUpdatePermission: PropTypes.bool,
  createSimpleOrder: PropTypes.bool,
  userIdAVVAL: PropTypes.number,
};
export default enhancer(CustomerOrderList);
